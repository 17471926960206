import { Container, Flex } from "@radix-ui/themes";

const RD2022 = () => {
  return (
    <Container size="4" align="center" width="100%">
      <Flex
        direction="column"
        align="center"
        justify="center"
        gap={{ initial: "4", md: "8" }}
      >
        <h1 className="text-2xl md:text-3xl font-bold text-primaryColor text-center">
          Research Day 2022 - Recap
        </h1>

        <p className="text-primaryColor text-sm md:text-md font-medium text-justify">
          Dubai, UAE: With the aim of bridging the gap between academic research
          and industry requirements and fostering fruitful exchanges amongst
          students, faculty and industry stalwarts, BITS Pilani Dubai Campus
          (BPDC) for the very first time organised a Research Day last week. The
          day was marked by the enthusiastic participation of over 150 attendees
          from various academic institutions and industries spanning various
          disciplines of biotechnology, engineering, general sciences,
          humanities, and social sciences. A panel discussion that featured
          eminent members of industry and academia as members provided an
          enriching experience for the attendees. Over 50 research scholars
          presented their works through oral and poster presentations and
          showcased their research to industry professionals and fellow
          students, inspiring the exchange of innovative ideas across a wide
          breadth of global challenges.
          <br />
          <br />
          Corporate leaders and public sector policymakers from institutions
          such as DEWA, RTA, Invygo, Covestro Middle East LLC, Master Builders
          Solutions Construction Chemicals, TUV Nord and Unique World Robotics
          attended the event and evaluated the presentations by the research
          scholars.
          <br />
          <br />
          The posters presented by the research scholars highlighted recent
          research in BPDC on topics of great current and future relevance such
          as food packaging, therapeutic cancer treatment, bioactive compounds
          in UAE plants, wastewater treatment, energy-efficient buildings,
          photocatalysis, sustainable bus services on the PPP model, use of
          artificial intelligence and machine learning in early detection of
          plant diseases for food security and smart packaging, IoT in intrusion
          detection, smart parking, intelligent robotic applications in
          hazardous environments and manufacturing, and design and development
          of micro-vortex generators for aerospace applications. Posters from
          humanities and social sciences included studies on South Asian
          immigrant entrepreneurs in UAE, e-commerce and brick & mortar stores
          for the fashion retail sector in GCC countries.
          <br />
          <br />
          Welcoming the delegates, Prof. Srinivasan Madapusi, Director, BPDC,
          said, “BITS Research Day is an initiative by research scholars to make
          academic research more accessible and bring it to the real world. An
          effective alliance between academia and industry has the potential of
          boosting research activities for the larger benefit of society.
          Research Day is a platform that brings educational institutions and
          industry leaders together to form a symbiotic relationship to meet
          each other’s needs and create a substantial and sustainable win-win
          situation for all”.
          <br />
          <br />
          The event was graced by invited talks from prominent members of
          industry and academia. Prof. Pushpak Battacharya from IIT Bombay
          emphasized the need of natural language processing in a multilingual
          world. Prof. Ramesh Bansal from the University of Sharjah championed
          the cause of renewable energy. Prof. Sayak Bhattacharya, from the
          American University of Sharjah detailed current trends in research on
          robotics. Invited talks from the industry featured Dr. Angelika
          Eksteen and Mr. Nader Kabbara from AI Directions who presented the
          vast applicability of artificial intelligence in different sectors
          like healthcare, farming, retail and banking.
          <br />
          <br />A panel discussion entitled “Bridging the gap between academia
          and industry” was moderated by Dr. Snehaunshu Chowdhury of BPDC. Mr.
          Raed Higer, Sr. Principal Technologist, Dell, Mr. Aloysius Fernandes,
          President, Redington Gulf, Dr. Kanak Madrecha, Principal Consultant,
          Dr Kanak Madrecha & Associates FZ LLE, Ms. Amna Abdul Jalil, Additive
          Manufacturing Engineer, Sharjah Technology & Research Park, Mr.
          Revanand Athavale, VP, Schneider Electric presented their views on
          behalf of the industry. The academic points of view were put forth by
          Dr. Hafiz Ahmed, RAK Medical and Health Sciences University, Prof.
          Abdel-Hamid Mourad, UAE University and Prof. Sathish Kannan, American
          University of Sharjah.
          <br />
          <br />
          Concluding the event, Prof. R. Karthikeyan, Dean of Academic Graduate
          Studies and Research, BPDC, said, “The event provided the research
          scholars with scope to gain sufficient insight into real-world
          problems and provided them with an opportunity to connect with the
          industry while the people from the industry got a glimpse of the
          nature and quality of academic research being pursued in various
          universities which will lead to more collaborative research. All the
          attendees agreed that the research gap needs to be bridged by
          maintaining constant channels of communication to exploit the
          synergistic benefits of the academia-industry ecosystem”.
        </p>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          {Array.from({ length: 10 }, (_, index) => (
            <img
              key={index}
              loading="lazy"
              className="h-auto max-w-full rounded-lg object-cover object-center"
              src={`/assets/RD2022/image${index + 1}.jpg`}
              alt={`RD2022_${index + 1}`}
            />
          ))}
        </div>
      </Flex>
    </Container>
  );
};

export default RD2022;
