import { Container, Flex } from '@radix-ui/themes';

const RD2023 = () => {
  return (
    <Container size='4' align='center' width='100%'>
      <Flex
        direction='column'
        align='center'
        justify='center'
        gap={{ initial: '4', md: '8' }}
      >
        <h1 className='text-2xl md:text-3xl font-bold text-primaryColor text-center'>
          Research Day 2023 - Recap
        </h1>

        <p className='text-primaryColor text-sm md:text-md font-medium text-justify'>
          On Research Day 2023, held at BITS Pilani, Dubai Campus, a
          distinguished panel of experts in their respective fields delivered
          invited talks. It featured Dr. Pallavi Kishore, Senior Lecturer at
          Middlesex University, Dubai; Dr. R.K Singh, Principal Scientist in
          Crop Breeding at ICBA, Dubai; Dr. Krishnadas Nanath, Associate
          Professor at Middlesex University, Dubai; and Dr Jinane Mounsef,
          Assistant Professor at the Electrical and Electronic Engineering (EEE)
          department of RIT, Dubai.
          <br />
          <br />
          Dr. Pallavi Kishore discussed the relationship between CSR,
          sustainability, and profitability. Emphasizing the potential for
          businesses to thrive by embracing sustainable practices, she
          highlighted the benefits of integrating CSR initiatives with
          sustainability.
          <br />
          <br />
          Dr. R.K. Singh presented on sustainable agriculture, highlighting the
          need to address environmental challenges while ensuring food security.
          He highlighted the importance of resilient crop varieties and
          precision agriculture techniques to optimize farming practices and
          resource efficiency.
          <br />
          <br />
          Dr. Krishnadas Nanath explored the role of AI in creating a
          sustainable digital economy. He discussed the importance of aligning
          AI's intent and outcome with sustainability goals and pointed out the
          concept of "AI for Good" to achieve positive social and environmental
          impacts.
          <br />
          <br />
          Additionally, Dr. Jinane Mounsef showcased various research projects,
          including underwater drowning detection and language translation,
          highlighting their contributions to water safety and global
          connectivity. The talks and research projects presented at Research
          Day 2023 provided valuable insights into sustainability in
          agriculture, AI, and innovative research endeavors.
          <br />
          <br />
          The panel discussion on sustainability: Initiatives and Challenges was
          held with experts from various fields, including Prof. Hafiz Ahmed,
          Prof. Bhoomendra Bhongade, Prof. S M Faheem, Prof. Sathish Kannan, Mr.
          Moheet Vishwas, Mr. Sudeep Nair, Mr. Sylvian Latuilerie, and Mr.
          Krishna Kumar. The key points discussed were:
          <br />
          <br />
          Sustainability in Curriculum: Integrating sustainability into
          educational curricula to raise awareness and promote sustainable
          practices.
          <br />
          <br />
          Sustainability in Business: Businesses are striving to improve
          sustainability despite profit-oriented objectives. Independent
          auditing and frameworks are needed for quantifying sustainability
          efforts.
          <br />
          <br />
          Measurement and Assessment of Sustainability: Key indicators for
          measuring sustainability were identified as energy usage and carbon
          footprint. However, ownership of carbon footprint data remains
          uncertain. The panel stressed the significance of grassroots
          initiatives and individual contributions in tackling pollution and
          achieving sustainable practices. The discussion also pointed out the
          importance of adopting sustainable processes in healthcare.
          <br />
          <br />
          The panel discussion highlighted the importance of integrating
          sustainability in education, business, and healthcare. It underlined
          the need for quantifying sustainability efforts, addressing social
          imbalances caused by AI, and engaging individuals in grassroots
          initiatives. Collaboration between individuals, organizations, and
          governments is essential for effectively measuring and assessing
          sustainability.
        </p>

        <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
          {Array.from({ length: 15 }, (_, index) => (
            <img
              key={index}
              loading='lazy'
              className='h-auto max-w-full rounded-lg object-cover object-center'
              src={`/assets/RD2023/image${index + 1}.jpg`}
              alt={`RD2023_${index + 1}`}
            />
          ))}
        </div>
      </Flex>
    </Container>
  );
};

export default RD2023;
