import { Container, Flex, Table } from '@radix-ui/themes';

import { experts } from '../../constants/posters';

const EventDescription = () => {
  return (
    <Container
      size='4'
      align='center'
      width='100%'
      px={{
        initial: '5',
        md: '0',
      }}
    >
      <Flex
        direction='column'
        align='center'
        justify='center'
        gap={{ initial: '3', md: '6' }}
      >
        <h1 className='text-2xl md:text-3xl font-bold text-blackColor text-center'>
          Events Description
        </h1>

        <Flex direction='column' align='start' gap={{ initial: '1', md: '3' }}>
          <h3 className='text-xl md:2xl font-semibold text-blackColor'>
            INVITED SPEAKERS
          </h3>

          <p className='text-primaryColor text-md md:text-lg font-medium text-justify'>
            Our esteemed guest speakers will delve into the exciting world of
            translational research! These talks will showcase how cutting-edge
            technologies are bridging the gap between scientific discovery and
            real-world solutions. Prepare to be captivated by in-depth
            discussions and long-term perspectives, equipping you with valuable
            knowledge to navigate the challenges of our times.
          </p>

          <ul className='text-primaryColor text-md md:text-lg font-medium list-disc list-inside text-pretty'>
            <li>
              Dr. Michael Baddeley, Research Lead, Technology Innovation
              Institute (TII), Abu Dhabi.
            </li>

            <li>
              Ms. Anishkaa Gehani, co-founder, Gulf IACCEL Business Incubator,
              Dubai.
            </li>

            <li>Ms Manisha Jadhav, IP Head, Awalnut IPR Consultants, Dubai.</li>

            <li>
              Dr Sameer Kishore, Associate Professor, Middlesex University,
              Dubai.
            </li>

            <li>
              Mr. Jasveer R Beni R&D Manager - Cementitious Systems, Technology
              Center, Dubai.
            </li>
          </ul>
        </Flex>

        <Flex direction='column' align='start' gap={{ initial: '1', md: '3' }}>
          <h3 className='text-xl md:2xl font-semibold text-blackColor'>
            PANEL DISCUSSION
          </h3>

          <p className='text-primaryColor text-md md:text-lg font-medium text-justify'>
            Our panel discussion dives deep into the critical role of
            collaboration across academic disciplines. We'll explore how
            researchers from diverse fields can join forces to develop impactful
            solutions for sustainability. By fostering interdisciplinary
            exchange, we aim to shed light on the importance of this
            collaborative approach in achieving truly sustainable research
            outcomes.
          </p>

          <ul className='text-primaryColor text-md md:text-lg font-medium list-disc list-inside text-pretty'>
            <li>
              Dr. Jinane Mounsef, Assistant Professor. Department of Electrical
              Engineering, RIT Dubai.
            </li>

            <li>
              Mr. Joy Sebastian, CEO, Techgentsia Software Technologies Pvt.
              Ltd., India.
            </li>

            <li>Mr. Arun Mohan, CEO ADfolks, Dubai.</li>

            <li>
              Mr. Arun Kumar, Associate Director, Technology and Innovation
              Research, Fuld and Company.
            </li>

            <li>
              Dr. Hafiz Ahmed, Associate Professor, RAK Medical & Health
              Sciences University.
            </li>

            <li>Mr. Sreedhar Bevara, CEO, BMR Consulting.</li>

            <li>Mr. Renjit Philip, Partner- Start2Scale Consulting (MENA)</li>
          </ul>
        </Flex>

        <Flex direction='column' align='center' gap={{ initial: '1', md: '3' }}>
          <h3 className='text-xl md:2xl font-semibold text-blackColor'>
            EXPERTS
          </h3>

          <Table.Root
            layout='auto'
            size='3'
            className='border-blackColor border-solid border-2 rounded-lg shadow-lg'
          >
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Title</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {experts.map((event) => (
                <Table.Row key={event.title}>
                  <Table.Cell>{event.title}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        </Flex>
      </Flex>
    </Container>
  );
};

export default EventDescription;
