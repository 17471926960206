export const phd = [
  {
    posterNo: 'BIO 01',
    title: `Production of Bioenzyme from organic wastes as Multipurpose solution for replacement to toxic chemicals based products`,
  },
  { posterNo: 'BIO 02', title: `Three Dimensional Printed Biofilms` },
  {
    posterNo: 'BIO 03',
    title: `Role of Intrinsically Disordered Regions in transcriptional regulation of gene expression`,
  },
  {
    posterNo: 'BIO 04',
    title: `Large scale cultivation of microalgae for production of biofuels: A Biorefinary approach`,
  },
  {
    posterNo: 'BIO 05',
    title: `Marine flora: an environmentally sustainable solution to turn marine biomass and waste to value-added products.`,
  },
  { posterNo: 'BIO 06', title: `Breast cancer therapeutics` },
  {
    posterNo: 'BIO 07',
    title: `Tyrosine kinase inhibitor loaded zein nanoparticle for anticancer studies`,
  },
  {
    posterNo: 'BIO 08',
    title: `Multi-Omics analysis of pan heat shock proteins in liver cancer identifies key therapeutic targets`,
  },
  {
    posterNo: 'BIO 09',
    title: `Shift in metabolome with ketogenic diet and its effect on wound healing`,
  },
  {
    posterNo: 'MEC 01',
    title: `Study of residual stresses and microstructure development during the fabrication of light alloys`,
  },
  {
    posterNo: 'MEC 02',
    title: `Composition Prediction and Statistical Analysis on Bulk Metallic Glasses`,
  },
  {
    posterNo: 'CHE 01',
    title: `Wastewater treatment and hydrogen production through solar driven photocatalytic process`,
  },
  {
    posterNo: 'CHE 03',
    title: `Sustainable self-healing smart coating design and development using an intrinsic mechanism`,
  },
  {
    posterNo: 'CHE 04',
    title: `Modelling & Simulation for the Sustainable Recovery of Aromatics (BTX) from Petrochemical Industrial Wastewater`,
  },
  {
    posterNo: 'CIV 01',
    title: `Thermal insulation panels from recycled cardboard for retrofitting buildings in hot climate`,
  },
  {
    posterNo: 'CIV 02',
    title: `Development Methodology for Commercially Sustainable Bus on Demand Services`,
  },
  {
    posterNo: 'CIV 03',
    title: `Composition optimization of LC3 ternary blended cement.`,
  },
  {
    posterNo: 'CIV 04',
    title: `Temperature Effect on the Long Span Continuous Pre-Stressed Concrete Bridges`,
  },
  { posterNo: 'CIV 06', title: `Effect of Climate Change on Biodiversity` },
  {
    posterNo: 'CIV 07',
    title: `Optimising Building Energy Performance: Multi-objective NSGA II Strategies for Daylighting and Visual Comfort Enhancement`,
  },
  {
    posterNo: 'CSE 02',
    title: `AIR-UNet++: A Deep Learning Framework for Histopathology Image Segmentation`,
  },
  {
    posterNo: 'CSE 05',
    title: `Hybrid Deep learning models for anomaly detection in multivariate timeseries data`,
  },
  {
    posterNo: 'CSE 06',
    title: `Microbiome data analysis for gene prediction and drug interaction prediction using Deep learning`,
  },
  {
    posterNo: 'CSE 07',
    title: `OptiRet-Net: An Optimized Low-Light Image Enhancement Technique for CV-based Applications in Resource-Constrained Environments`,
  },
  {
    posterNo: 'CSE 08',
    title: `xAIL-ID: Explainable AI-based LSTM model for Intrusion Detection for the Internet of Things`,
  },
  {
    posterNo: 'CSE 09',
    title: `Innovative Plant Disease Forecasting and Categorization Platform`,
  },
  {
    posterNo: 'CSE 11',
    title: `Insight into Motor Imagery: EEGNet-Enhanced Prediction with Sliding Window Analysis`,
  },
  {
    posterNo: 'CSE 12',
    title: `A Lightweight Video Anomaly Detection System based on Attention Mechanism`,
  },
  {
    posterNo: 'CSE 13',
    title: `Detection and Prevention of Evasion Attacks on Machine Learning Models`,
  },
  { posterNo: 'CSE 14', title: `Recommendation System using Knowledge Graph` },
  { posterNo: 'CSE 15', title: `Disagreement Problem in Explaianble AI` },
  {
    posterNo: 'CSE 16',
    title: `AN OVERVIEW OF DEEP LEARNING NOVEL MODELS FOR MEDICAL IMAGING`,
  },
  {
    posterNo: 'CSE 17',
    title: `Effective Preprocessing of Datasets by Removing Anomalies with OptiContamFinder on Isolation Forest for Stock Market Forecasting`,
  },
  {
    posterNo: 'CSE 18',
    title: `AR/VR Assisted Digital Twins in Mobile Robots`,
  },
  {
    posterNo: 'CSE 19',
    title: `Optimized Data Finetuning For Lung Disease Diagnosis`,
  },
  {
    posterNo: 'CSE 20',
    title: `Cybersecurity posture management on the digital twin of a simulated hybrid infrastructure`,
  },
  {
    posterNo: 'CSE 21',
    title: `Object Detection, Counting and Measurement using Deep Learning`,
  },
  {
    posterNo: 'CSE 22',
    title: `Next-Gen Erp testing : leveraging Machine Learning for automated UAT`,
  },
  {
    posterNo: 'CSE 23',
    title: `Explainable AI-Driven Personalized Nutrition: Integrating Microbiome Analysis and Knowledge Graphs for Transparent Recommendations`,
  },
  {
    posterNo: 'CSE 24',
    title: `ENHANCING ENTERPRISE RESOURCE PLANNING SYSTEMS THROUGH ARTIFICIAL INTELLIGENCE`,
  },
  {
    posterNo: 'EEE 01',
    title: `Enhancing Wastewater Treatment Plant Efficiency: Constraint Optimization and Regression Analysis Approach`,
  },
  {
    posterNo: 'EEE 02',
    title: `Enhanced Adder Design with 8T SRAM Integration for In-Memory Computing Paradigms`,
  },
  {
    posterNo: 'EEE 03',
    title: `Automatic breast cancer detection and classification using mammogram images:AI approach`,
  },
  {
    posterNo: 'EEE 04',
    title: `Predictive modelling and Benchmarking of different type of Building applications in UAE using ANN and KNN`,
  },
  {
    posterNo: 'EEE 05',
    title: `Dynamic Environment Navigation using Sensor Fusion Techniques and Path Planning for Mobile Robot`,
  },
  { posterNo: 'EEE 06', title: `Reconfigurablle Antenna` },
  {
    posterNo: 'EEE 07',
    title: `Modeling of Security of Supply (SoS) for Electrical Power Network`,
  },
  {
    posterNo: 'EEE 08',
    title: `Virtual power plants and energy management with hybrid energy sources`,
  },
  {
    posterNo: 'EEE 09',
    title: `Intelligent model of Renewable Energy Management System for Net-zero Buildings`,
  },
  { posterNo: 'EEE 10', title: `Optmising the Hall-Héroult Smelting Process` },
  {
    posterNo: 'GS 01',
    title: ` Design and Development of FET-Based Sensor for Bio-detection using Nano Materials`,
  },
  {
    posterNo: 'GS 02',
    title: ` Interaction studies of doped nanoparticle with mephalan drug- DFT study`,
  },
  {
    posterNo: 'GS 03',
    title: ` Deciphering Plastic Degradation through Artificial Aging`,
  },
  {
    posterNo: 'HSS 02',
    title: `Effect of Flexible Work Options, Perceived Occupational Stress and Intra-Organizational Social Capital on Expatriate Job Performance: Empirical Evidence`,
  },
  {
    posterNo: 'HSS 04',
    title: `International Migration, Remittances and Achieving Sustainable Development Goals in India`,
  },
  {
    posterNo: 'HSS 05',
    title: `Assessing United Arab Emirates Policy and Public Response: The Nation's Approach to Global Climate Change Justice`,
  },
  {
    posterNo: 'HSS 06',
    title: `Impact of Authentic Leadership on the Job Performance of Employees`,
  },
  {
    posterNo: 'HSS 07',
    title: `Global Supply Chain Resilience - Geopolitical analysis of decoupling China`,
  },
  {
    posterNo: 'MGT 03',
    title: `Extreme Risk Spillover in the GCC Stock Markets`,
  },
  {
    posterNo: 'MGT 04',
    title: `A study on Ambidextrous Leadership and its Outcomes – Evidence from UAE`,
  },
  {
    posterNo: 'MGT 06',
    title: `Analysis of Key Drivers of Family Business Branding: An Integrated ISM-MICMAC Approach`,
  },
  {
    posterNo: 'MEC 03',
    title: `Experimental Investigations on Anion Exchange Membrane (AEM) Based Passive Direct Methanol Fuel Cell(DMFC)`,
  },
  {
    posterNo: 'MEC 04',
    title: `Computational modeling to predict the response of buried pipelines under lateral compression`,
  },
  {
    posterNo: 'MEC 05',
    title: `Lean Six Sigma - Industry 4.0 for quality control and predictive maintenance`,
  },
  {
    posterNo: 'MEC 06',
    title: `Aerodynamics of NACA4412 Wing with Micro Vortex Generator in Ground Effect`,
  },
  {
    posterNo: 'MEC 08',
    title: `DFT Study on Novel Zirconium-Rich Multicomponent Alloy for Biomedical Applications `,
  },
  {
    posterNo: 'MEC 09',
    title: `Effect of TCW-SAW on thick 316L stainless steel plates`,
  },
  {
    posterNo: 'MEC 13',
    title: `Implementation of Lean Six Sigma Methodology in Construction Quality Control`,
  },
];

export const first_higher = [
  {
    posterNo: 'CSF 01',
    title: `Design, Implementation and Analysis of a 3D Computer Graphics Visualization Application using VR Technology`,
  },
  {
    posterNo: 'CSF 02',
    title: `CVI-UNet: Automated Choroidal Vascularity Index measurement from OCT Images for Monitoring Ocular and Systemic Diseases`,
  },
  {
    posterNo: 'CSF 03',
    title: `Context Aware UNet for Brain Tumor Segmentation from Brain MRI Images`,
  },
  {
    posterNo: 'CSF 04',
    title: `Frame-Level Facial Anti-Spoofing Using a CNN Ensemble Model`,
  },
  {
    posterNo: 'CSF 05',
    title: `100% Virtual Roleplay through Interactive Design and Integration Using OpenAI `,
  },
  { posterNo: 'CSF 06', title: `GenAI for Sign Language Synthesis` },
  { posterNo: 'CSF 07', title: `Gamified Quiz: An Assessment Platform` },
  { posterNo: 'CSF 08', title: `GradeScale: LLM Based Essay Grading System` },
  { posterNo: 'CSF 10', title: `VR/MR for mobile robot navigaion and control` },
  {
    posterNo: 'EEF 01',
    title: `Autonomous Underwater Robot for Aquatic Surveillance`,
  },
  {
    posterNo: 'EEF 02',
    title: `Intelligent Surface Cleaning Robot by Team IFOR students`,
  },
  {
    posterNo: 'EEF 03',
    title: `Enhanced Biogas Production for Sustainable Electricity via AI backed waste Management`,
  },
  {
    posterNo: 'EEF 05',
    title: `Quantifying Alzheimer's Pathology through EEG Signal Processing and Machine Learning`,
  },
  {
    posterNo: 'EEF 06',
    title: `Power Quality Improvement using dualy powered UPQC for microgrids,`,
  },
  { posterNo: 'CHF 01', title: `Sutainable Coating using natural resources` },
  {
    posterNo: 'CHF 02',
    title: `Production of biofuel form wastes: Trash to Cash Initiative`,
  },
  {
    posterNo: 'CHF 03',
    title: `An approach towards reducing carbon emissions using advanced methods`,
  },
  {
    posterNo: 'MEF 01',
    title: `Investigation of flow around dragon-fly inspired corrugated airfoil`,
  },
  {
    posterNo: 'MEF 02',
    title: `Design and Development of Bioinspired Robotic Fish for Underwater Surveillance`,
  },
  { posterNo: 'ECF 01', title: `1.5 degree-Plant Based Dairy` },
  { posterNo: 'ECF 02', title: `Sustvest-Driving Sustainable Investments` },
  { posterNo: 'ECF 03', title: `Genme - Streamlining Pharma B2B Supply Chain` },
  { posterNo: 'ECF 04', title: `Krypsm Advisors` },
];

export const experts = [
  { title: `Dr. Dinesh Patel, Covestro Middle East` },
  { title: `Dr. Keyur Acharya, Covestro Middle East` },
  { title: `Mr. Jasveer R Beni - Middle East Sika MBCCLLC` },
  {
    title: `Dr. Michael Schimdt - Middle East-Sika MB Construction Chemicals LLC.`,
  },
  { title: `Ms. Lorna Reyes Sika UAE LLC.` },
  { title: `Dr. K.V.Bhaskar Rao, Hydroponics Specialist, Consultant, Mentor` },
  { title: `Dr. Arif Hussain, MAHE` },
  { title: `Dr. Faheem, MAHE` },
  { title: `Dr. Henda Mahmoudi, M.Sc., Ph.D. Plant Physiologist, ICBA` },
  { title: `Dr. Hafiz Ahmed, RAKMHSU` },
  { title: `Dr. Imran Khan, Bureauveritas` },
  {
    title: `Dr. Narayani Rajagopalan (Manager - Product Innovation & Technology, R&D Department) from National Paints, Sharjah`,
  },
  { title: `Prof. Sathish Kannan Dean, Research` },
  { title: `Prof. Salman Pervaiz, RIT, Meachanical` },
  { title: `Prof. Vishal Naranje, Amity` },
  { title: `Mr. Sheyas Patwardhan, Reckitt benckiser` },
  { title: `Dr. Bhopendra Singh, AMITY University` },
  { title: `Mr. Khalid Sheikh, Prognica Lab, Dubai` },
  { title: `Prof. R C Bansal, Sharjah University` },
  { title: `Prof. Chithirai Pon Selvan, Curtin University, Dubai ` },
  { title: `Mr. Mohsin Pathan, Bahwan Cybertech` },
  { title: `Dr.Sameer Kishore, Middlesex` },
  { title: `Dr.Judhi Prasetyo, Middlesex` },
  { title: `Mr Shrirang Jahagirdar, General Manager, Optimum Measures` },
  { title: `Dr. Alavikunhu Panthakkan, University of Dubai` },
  { title: `Mr. Aamir Ashraf Mir, Key Account Manager, Noventiq` },
  {
    title: `Dr. Sharat Pillalamarri, Chief Specialist - Roads & Transport Authority, Dubai`,
  },
  { title: `Mr. Jacob joy, Partner, Fides Technical Consultants and Services` },
  { title: `Mr. Aslan Kashkooli, Architect, SOMA` },
  { title: `Dr. Muhammad Azeem, Westford Education Group` },
  { title: `Prof. Shaista Anwar, Liwa College, Abu Dhabi UAE` },
  { title: `Prof. Ahmad, IMT Dubai` },
  { title: `Prof. Mohammed, Dubai University` },
  { title: `Prof. Anam, IMT Dubai` },
  { title: `Dr. Paresh, Hitech Resins` },
];
