import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import RD2022 from './pages/RD2022';
import RD2023 from './pages/RD2023';
import Landing from './pages/Landing';
import Register from './pages/Register';
import Layout from './components/Layout';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Layout>
                <Landing />
              </Layout>
            }
          />
          <Route
            path='/2022'
            element={
              <Layout>
                <RD2022 />
              </Layout>
            }
          />
          <Route
            path='/2023'
            element={
              <Layout>
                <RD2023 />
              </Layout>
            }
          />
          <Route
            path='/register'
            element={
              <Layout>
                <Register />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
