import React, { Suspense } from 'react';

import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import { Toaster } from './Toaster';

function Layout({ children }) {
  return (
    <React.Fragment>
      <main className='min-h-dvh w-screen flex flex-col justify-between gap-5 md:gap-10 bg-secondaryColor'>
        <Header />
        <div className='pt-16 md:pt-5 px-5 lg:px-0'>
          <Suspense fallback={<Loading />}>{children}</Suspense>

          <Toaster />
        </div>

        <Footer />
      </main>
    </React.Fragment>
  );
}

export default Layout;
