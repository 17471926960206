import '@radix-ui/themes/styles.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Theme } from '@radix-ui/themes';
import { ThemeProvider } from '@material-tailwind/react';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Theme>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Theme>
  </React.StrictMode>
);
