import { Container, Flex, Table } from '@radix-ui/themes';

import { phd, first_higher } from '../../constants/posters';

const Posters = () => {
  return (
    <Container
      size='4'
      align='center'
      width='100%'
      px={{
        initial: '5',
        md: '0',
      }}
    >
      <Flex
        direction='column'
        align='center'
        justify='center'
        gap={{ initial: '3', md: '6' }}
      >
        <h1 className='text-2xl md:text-3xl font-bold text-blackColor text-center'>
          PHD Degree Scholars
        </h1>

        <Table.Root
          layout='auto'
          size='3'
          className='border-blackColor border-solid border-2 rounded-lg shadow-lg'
        >
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Poster No.</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Title</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {phd.map((event) => (
              <Table.Row key={event.posterNo}>
                <Table.Cell>{event.posterNo}</Table.Cell>
                <Table.Cell>{event.title}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>

        <h1 className='text-2xl md:text-3xl font-bold text-blackColor text-center'>
          First/Higher Degree Scholars
        </h1>

        <Table.Root
          layout='auto'
          size='3'
          className='border-blackColor border-solid border-2 rounded-lg shadow-lg'
        >
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Poster No.</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Title</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {first_higher.map((event) => (
              <Table.Row key={event.posterNo}>
                <Table.Cell>{event.posterNo}</Table.Cell>
                <Table.Cell>{event.title}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Flex>
    </Container>
  );
};

export default Posters;
