import { Toaster as Sonner } from 'sonner';

const Toaster = ({ ...props }) => {
  return (
    <Sonner
      theme='light'
      className='toaster group'
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-whiteColor group-[.toaster]:text-primaryColor group-[.toaster]:text-base group-[.toaster]:border-2 group-[.toaster]:border-primaryColor group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-primaryColor',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primaryColor',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-primaryColor',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
