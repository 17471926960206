import { Container, Flex } from '@radix-ui/themes';

const Footer = () => {
  return (
    <Container
      width='100%'
      className='bg-primaryColor rounded-tl-[50px] rounded-tr-[50px] max-h-[400px] md:max-h-[400px] grid place-items-center bottom-0'
      p={{ initial: '6', md: '9' }}
    >
      <Flex
        direction='column'
        align='center'
        justify='center'
        width='100%'
        gap={{ initial: '5', md: '8' }}
      >
        <p className='text-center text-whiteColor text-sm md:text-lg'>
          <span className='text-lg md:text-xl font-semibold'>CONTACT</span>
          <br />
          <br />
          Prof. R Karthikeyan (rkarthikeyan@dubai.bits-pilani.ac.in)
          <br />
          Dr. Brij Kishor Pandey (brijpandey@dubai.bits-pilani.ac.in)
          <br />
          Dr. TamizharasanPeriyasamy (tamizharasan@dubai.bits-pilani.ac.in)
          <br />
          Academic Graduate Studies and Research Division (AGSRD) Associate
          Dean, (AGSRD)
          <br />
          Ph no: 0501571477
        </p>

        <Flex direction='row' align='center' justify='between' width='100%'>
          <Flex direction='row' align='center' gap='1'>
            <a
              href='https://twitter.com/bitspilanidubai'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='/socials/x.png'
                alt='X'
                className='invert w-[25px] md:w-[40px]'
              />
            </a>

            <a
              href='https://www.instagram.com/bitspilanidubai/'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='/socials/instagram.png'
                alt='Instagram'
                className='invert w-[25px] md:w-[40px]'
              />
            </a>

            <a
              href='https://www.youtube.com/channel/UClKXaO8gB10OObAkoEkyNDQ'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='/socials/youtube.png'
                alt='Youtube'
                className='invert w-[25px] md:w-[40px]'
              />
            </a>

            <a
              href='https://www.linkedin.com/school/birla-institute-of-technology-and-science-pilani-dubai/'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='/socials/linkedin.png'
                alt='LinkedIn'
                className='invert w-[25px] md:w-[40px]'
              />
            </a>
          </Flex>
          <h1 className='text-lg md:text-2xl font-bold text-whiteColor'>
            R&IDAY@BITS2024
          </h1>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Footer;
