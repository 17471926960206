import { query, getDocs, collection, where } from 'firebase/firestore';
import { firestore } from '../services/firebase';
import { sha256 } from 'crypto-hash';

export const checkDuplicateEntry = async ({ email }) => {
  const currentYear = new Date().getFullYear();
  const q = query(
    collection(firestore, `registrations/${currentYear}/rday`),
    where('email', '==', email)
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return false;
  } else {
    return true;
  }
};

export const getDocument = async ({ email }) => {
  const currentYear = new Date().getFullYear();
  const q = query(
    collection(firestore, `registrations/${currentYear}/rday`),
    where('email', '==', email)
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs;
};

export const generateQrCodeAsDataUrl = (hashedId) => {
  var QRCode = require('qrcode');
  var qrUrl = '';
  QRCode.toDataURL(hashedId, function (err, url) {
    qrUrl = url;
  });
  return qrUrl;
};

export const hashValue = async (value) => {
  const localHash = await sha256(value);
  return localHash;
};
