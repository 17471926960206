import { Navbar } from '@material-tailwind/react';
import { Link, useLocation } from 'react-router-dom';

const navItems = [
  {
    title: '2022',
    url: '/2022',
  },
  {
    title: '2023',
    url: '/2023',
  },
];

const Header = () => {
  const { pathname } = useLocation();

  return (
    <Navbar
      fullWidth
      color='transparent'
      className='px-3 py-3 absolute top-0 z-20 flex items-center justify-between'
    >
      <Link to='/' className='w-[120px] md:w-[150px] h-auto'>
        <img
          alt='logo'
          src='/assets/logos/BITSLogoHorizontal.png'
          className='w-full h-full object-contain'
        />
      </Link>

      <div className='flex flex-row items-center gap-5 md:gap-10 mr-2 md:mr-4'>
        {navItems.map((item) => (
          <Link
            key={item.title}
            to={item.url}
            className={`text-xl md:text-2xl font-bold ${
              pathname === '/'
                ? 'text-whiteColor hover:text-primaryColor'
                : 'text-primaryColor hover:text-whiteColor'
            } transition-colors`}
          >
            {item.title}
          </Link>
        ))}
      </div>
    </Navbar>
  );
};

export default Header;
