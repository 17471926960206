export const events = [
  { time: '08:30', event: 'Registration [Experts and Students]' },
  {
    time: '09:00',
    event: `Inaugural Function, Quran Recitation, Prayer song, Lighting [ Invited speakers/ A Deans], Scope of the event & Welcome Address, Prof R Karthikeyan, Inaugural Address by Director, Prof. Srinivasan Madapusi`,
  },
  {
    time: '09:30',
    event:
      'Invited Talks (Each 25 min. each), Mr. Shalabh Jakhetia, Managing Partner & Chief Operating Officer; "How to register startups in UAE"',
  },
  {
    time: '10:00',
    event: `Ms Manisha Jadhav, IP Head at Awalnut IPR Consultants; "Patent filing process in India and UAE and Patent law and IPR"`,
  },
  {
    time: '10:00',
    event: `Dr Jasveer R Beni R&D Manager - "Cementitious Systems, Technology Center Dubai; Title "3 D Printing in Construction"`,
  },
  {
    time: '11:00',
    event: `Dr. Michael Baddeley, Research Lead, Technology Innovation Institute (TII), Abu Dhabi; Title "Cybersecurity Trends and Challenges ahead"`,
  },
  {
    time: '11:30',
    event: `Dr Sameer Kishore, Head and Founder, VRX Lab, Middlesex University; "Understanding the 'Self' in Immersive Virtual Reality", Momento distribution & Photo`,
  },
  {
    time: '12:00',
    event: `Panel Discussion 'Technology, Innovation and Entrpreneurship - UAE perspective', Dr Gulshan kumar, Assistant Professor, BITS Pilani Dubai Campus, Dr. Jinane Mounsef, Professor, EEE, RIT, Dr. Hafiz Ahmed, Professor, RAKMHSU, Mr. Arun Mohan , CEO ADfolks, Dubai, Mr. Joy Sebastian, CEO, Techgentsia Software Technologies Pvt. Ltd., Mr. Renjit Philip, Partner- Start2Scale Consulting (MENA), Mr Arun Kumar, Associate Director, Technology and Innovation Research, Fuld and Company, Momento distribution & Photo`,
  },
  { time: '01:00', event: 'Lunch' },
  {
    time: '01:30',
    event: 'Video presentation',
  },
  {
    time: '02:00',
    event: 'Poster Session & EVALUATION',
  },
  {
    time: '04:00',
    event: 'FINALIZING SCORES',
  },
  {
    time: '04:30',
    event: `Prize distribution & Valedictory, Certificate distribution (all posters), Certificate & prize distribution (best posters), Certificate & prize distribution (best Projects for First Degree), Recognition of collaborators, Vote of Thanks`,
  },
  {
    time: '05:00',
    event: 'Photo Session WITH ALL SCHOLARS, EXPERTS',
  },
];
