import { Container, Flex } from '@radix-ui/themes';

import RegisterForm from '../components/register/RegisterForm';

const Register = () => {
  return (
    <Container size='3' align='center' width='100%'>
      <Flex
        width='100%'
        align='center'
        direction='column'
        gap={{ initial: '4', sm: '8' }}
      >
        <h1 className='text-2xl md:text-3xl font-bold text-primaryColor text-center'>
          REGISTER
        </h1>

        <RegisterForm />
      </Flex>
    </Container>
  );
};

export default Register;
