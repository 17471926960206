import Hero from '../components/landing/Hero';
import About from '../components/landing/About';
import Posters from '../components/landing/Posters';
import Schedule from '../components/landing/Schedule';
import EventDescription from '../components/landing/EventDescription';

const Landing = () => {
  return (
    <div className='flex flex-col items-center justify-center gap-4 md:gap-8 -mt-16 md:-mt-5 -mx-5 lg:-mx-0'>
      <Hero />

      <About />

      <EventDescription />

      <Schedule />

      <Posters />
    </div>
  );
};

export default Landing;
