import { Container, Flex, Table } from '@radix-ui/themes';

import { events } from '../../constants/schedule';

const Schedule = () => {
  return (
    <Container
      size='4'
      align='center'
      width='100%'
      px={{
        initial: '5',
        md: '0',
      }}
    >
      <Flex
        direction='column'
        align='center'
        justify='center'
        gap={{ initial: '3', md: '6' }}
      >
        <h1 className='text-2xl md:text-3xl font-bold text-blackColor text-center'>
          SCHEDULE
        </h1>

        <Table.Root
          layout='auto'
          size='3'
          className='border-blackColor border-solid border-2 rounded-lg shadow-lg'
        >
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Time</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Event</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {events.map((event) => (
              <Table.Row key={event.time}>
                <Table.Cell>{event.time}</Table.Cell>
                <Table.Cell>{event.event}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Flex>
    </Container>
  );
};

export default Schedule;
