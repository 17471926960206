import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from 'firebase/storage';
import {
  Button,
  Input,
  Option,
  Select,
  Spinner,
} from '@material-tailwind/react';
import { toast } from 'sonner';
import { useState } from 'react';
import emailjs from 'emailjs-com';
import { doc, setDoc } from 'firebase/firestore';
import { Container, Flex, Grid } from '@radix-ui/themes';

import {
  checkDuplicateEntry,
  generateQrCodeAsDataUrl,
  getDocument,
  hashValue,
} from '../../helper/helper_functions';
import { categories } from '../../constants/registerForm';
import { firestore, storageInstance } from '../../services/firebase';

const RegisterForm = () => {
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState(false);
  const [bottomLoading, setBottomLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    data.category = selectedCategory;

    // checking duplicate entry on db
    const isDuplicate = await checkDuplicateEntry({ email: data.email });
    if (isDuplicate) {
      toast.error('A Registration with the same email already exists');
      setLoading(false);
      return;
    }
    const hashedId = await hashValue(data?.email);
    // uploading user qr code
    const qrCodeBase64 = generateQrCodeAsDataUrl(hashedId);
    const qrCodeRef = ref(
      storageInstance,
      `qrCodes/${currentYear}/rday/${data.email}.png`
    );
    await uploadString(qrCodeRef, qrCodeBase64, 'data_url');
    const qrCodeDownloadUrl = await getDownloadURL(qrCodeRef);

    if (data.category?.toLowerCase().includes('presenter')) {
      const posterRef = ref(
        storageInstance,
        `files/${currentYear}/rday/poster/${data.category}/${data.name}-${data?.posterFile?.name}`
      );

      await uploadBytes(posterRef, data?.posterFile);
      toast.success('Poster file uploaded successfully!');

      const posterDownloadUrl = await getDownloadURL(posterRef);
      data.posterUrl = posterDownloadUrl;

      const videoRef = ref(
        storageInstance,
        `files/${currentYear}/rday/video/${data.category}/${data.name}-${data?.videoFile?.name}`
      );

      await uploadBytes(videoRef, data?.videoFile);
      toast.success('Video file uploaded successfully!');

      const videoDownloadUrl = await getDownloadURL(videoRef);
      data.videoUrl = videoDownloadUrl;
    }

    //To avoid the posterFile being sent as data
    const { posterFile, videoFile, ...dataToSend } = data;

    try {
      dataToSend.qrCodeUrl = qrCodeDownloadUrl;
      dataToSend.hashValue = hashedId;

      await setDoc(
        doc(
          firestore,
          `registrations/${currentYear}/rday`,
          dataToSend.hashValue
        ),
        dataToSend
      );

      toast.success('Registration created succesfully');

      await emailjs
        .send(
          'service_t8clwjo',
          'template_uit3x4i',
          dataToSend,
          'V2Yz1dwEESLY22zXz'
        )
        .then(() => {
          toast.success('Email sent successfully');
          setTimeout(() => {
            window.location.href = '/';
          }, 2000);
        });
    } catch (error) {
      console.error('Error in registration: ', error);
      toast.error('An error occured during registration');
    } finally {
      setLoading(false);
    }
  };

  const handleVideoSubmit = async (e) => {
    e.preventDefault();
    setBottomLoading(true);

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    try {
      const matchingDocuments = await getDocument({ email: data.email });
      if (matchingDocuments.length === 0) {
        toast.error(
          'No registration found, please create a new registration above.'
        );
        setBottomLoading(false);
        return;
      }
      const { category, name } = matchingDocuments[0]?.data();

      if (!category?.toLowerCase().includes('presenter')) {
        toast.error('Video files are only for presenters');
        return;
      }
      const videoRef = ref(
        storageInstance,
        `files/${currentYear}/rday/video/${category}/${name}-${data?.videoFile?.name}`
      );

      await uploadBytes(videoRef, data?.videoFile);
      toast.success('Video file uploaded successfully!');

      const videoDownloadUrl = await getDownloadURL(videoRef);
      data.videoUrl = videoDownloadUrl;
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setBottomLoading(false);
    }
  };

  return (
    <Container size='3' align='center' className='w-full'>
      <form onSubmit={handleSubmit}>
        <Flex
          width='100%'
          p={{
            initial: '5',
            sm: '8',
          }}
          direction='column'
          align='center'
          justify='center'
          gap={{ initial: '4', sm: '5' }}
          className='bg-[#fdfdfd] rounded-2xl'
        >
          <Select
            className='w-full text-primaryColor'
            label='Select Category'
            value={selectedCategory}
            onChange={(c) => setSelectedCategory(c)}
          >
            {categories.map((category, index) => (
              <Option key={index} value={category.label}>
                {category.label}
              </Option>
            ))}
          </Select>

          <Grid
            width='100%'
            align='center'
            gap={{ initial: '4', sm: '5' }}
            columns={{ initial: '1', sm: '2' }}
          >
            {selectedCategory &&
              categories
                .find((c) => c.label === selectedCategory)
                ?.fields.map((field, index) => (
                  <Input
                    className={
                      field.type === 'file'
                        ? 'flex w-full border border-input text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium'
                        : 'w-full text-primaryColor'
                    }
                    key={index}
                    type={field.type}
                    label={field.label}
                    name={field.name}
                    accept={field.accept ? field.accept : undefined}
                    required={field.name === 'videoFile' ? false : true}
                  />
                ))}

            {selectedCategory.includes('Presenter') && (
              <Button
                className='bg-primaryColor px-10'
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = '/assets/Poster_Template.pptx';
                  link.download = 'Poster_Template.pptx';
                  link.click();
                }}
              >
                Download Poster Template
              </Button>
            )}
          </Grid>

          {selectedCategory.includes('Presenter') && (
            <p className='text-sm md:text-lg text-center'>
              Poster and Video (1 min) filename should be your Poster No. (Eg -
              CSE02.pptx & CSE02.mp4)
            </p>
          )}

          <Button
            type='submit'
            className='bg-primaryColor px-10'
            disabled={!selectedCategory || loading}
          >
            {loading ? <Spinner className='w-full h-full' /> : 'Submit'}
          </Button>
        </Flex>
      </form>

      <form onSubmit={handleVideoSubmit}>
        <Flex
          width='100%'
          p={{
            initial: '5',
            sm: '8',
          }}
          direction='column'
          align='center'
          justify='center'
          gap={{ initial: '4', sm: '5' }}
          className='bg-[#fdfdfd] rounded-2xl mt-5 md:mt-10'
        >
          <p className='text-md md:text-xl text-primaryColor text-center font-semibold'>
            If you're a presenter and missed your video upload, please upload
            below.
          </p>

          <Grid
            width='100%'
            align='center'
            gap={{ initial: '4', sm: '5' }}
            columns={{ initial: '1', sm: '2' }}
          >
            <Input
              className={'w-full text-primaryColor'}
              type='email'
              label='Email'
              name='email'
              required
            />

            <Input
              className={
                'flex w-full border border-input text-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium'
              }
              type='file'
              label='Upload Video'
              name='videoFile'
              accept='video/mp4,video/x-m4v,video/*'
              required
            />
          </Grid>

          <p className='text-sm md:text-lg text-center'>
            Video (1 min) filename should be your Poster No. (Eg - CSE02.mp4)
          </p>

          <Button
            type='submit'
            className='bg-primaryColor px-10'
            disabled={loading}
          >
            {bottomLoading ? <Spinner className='w-full h-full' /> : 'Submit'}
          </Button>
        </Flex>
      </form>
    </Container>
  );
};

export default RegisterForm;
